import React from "react";
import { Row, Col, Container, Button} from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";

import Divider from '../../sections/akkadian/Divider';
import imgBanner from "../../assets/image/akkadian/teamworking.jpg";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import { clinicians, states } from "../../utils/team.js";


const ACT = () => {

  const localState = "ACT";
  const localState_long = "Australian Capital Territory";
  const localState_maincity = "Canberra";
  const localState_ppl = "Canberraans";
  const main_string = "Akkadian Health offers telehealth Services in Canberra and throughout the ACT.";
  const secondary_string = "Our clinical team has grown to include dozens of experienced psychiatrists, psychologists and mental health clinicians all driven by the same goal - to make a population impact on the mental health issues facing everyday Australians.";
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - Psychiatrists in {localState_long} | ADHD Assessments in {localState_maincity} </title>
		  <meta
                name="description"
                content="Akkadian Health offers ADHD assessments and psychiatric services in {localState_long} via telehealth. Serving {localState_maincity} and all regional areas."
            />
        </Helmet>
				  <style>{`
					table{
					 width:90%;
					}
					table, th, td {
					  border: 1px solid white;
					  border-collapse: collapse;
					  padding: 8px;
					  color: white;
					  margin-left: 5%
					}
					th, td {
					  background-color: #2fbfcf;
					}
				  `}</style>

        <div className="inner-banner">
            <Container>

            <Row id="about-us" className="justify-content-center mt-md-3 pt-24 pt-lg-29">
					<Col lg="9" xl="8">
						<div className="px-md-6 text-center mb-11 mb-lg-14">
							<h1 className="title gr-text-3 mb-9 mb-lg-12">{localState_maincity}, {localState_long} ADHD Diagnosis & Online Mental Health Services</h1>
                        </div>
                    </Col>
            </Row>

			<Row className="justify-content-center align-items-center">
				<Col md="12">
					<div className="section-title text-center mb-12 mb-lg-16">

          <h4>Welcome to Akkadian Health in {localState_maincity}, {localState_long}!</h4>


						<p className="gr-text-10 px-lg-8 mb-0">
							{main_string}
						</p>
						<br />
						<p className="gr-text-10 px-lg-8">
                            {secondary_string}
						</p>                      
            <p className="gr-text-10  px-lg-8">
                        Akkadian Health provides expert ADHD diagnosis and mental health services to all {localState_ppl}.
                    </p>      

                    <p className="gr-text-10  px-lg-8">
                        We’re here to support you every step of the way.
                    </p>                                 
					</div>
				</Col>
			</Row>					
				

            <Row className="gr-text-8 mt-12 mb-8">
                <Col className="mb-5">
                    <h4>Our Services in {localState_maincity}, {localState_long}:</h4>
                    <ul className="list-unstyled gr-text-8 px-8">
                        <li><span role="img" aria-label="bullet">•</span> <Link to="/services/adhd-testing">Adult ADHD testing</Link></li>
                        <li><span role="img" aria-label="bullet">•</span> <Link to="/services/general-psychiatry">General Psychiatry</Link></li>
                        <li><span role="img" aria-label="bullet">•</span> <Link to="/services/allied-health">ADHD Coaching & Psychology</Link></li>
                    </ul>
                </Col>


            </Row>


            {clinicians && clinicians.filter(clinician => clinician.state === localState).length > 0 && (
            <Row className="gr-text-8 mb-8 mt-12 px-4">
                <h4>Our local {localState} clinical team</h4>            
                <p className="gr-text-10">Our dedicated clinical team based in {localState_maincity}, {localState_long} consists of experienced professionals who specialise in ADHD care.</p>
                <hr />            
            </Row>    
            )}

				<Row>
					{clinicians && clinicians.filter(clinician => clinician.state === localState).map((clinician, index) => (
						<Col key={clinician.id} md="6" className="gr-text-8 mb-8 px-4 align-items-center">
							<img src={clinician.imageFile} alt={clinician.name} className="w-10 rounded-8" style={{ width: '90px', height: '120px' }} />

							<div>
								<Link
									to={`/our-team`}
									state={{ provider: `${clinician.id}` }}
									partiallyActive={true}
									style={{ color: "#2fbfcf", fontWeight: 'bold', textDecoration: 'none' }}
									className="mr-2 mb-2"
								>
									{clinician.name}
								</Link>
								<p style={{ color: "#b0b0b0", fontSize: '0.9rem', margin: '0' }}>
									{clinician.shortBio}
								</p>
							</div>
						</Col>
					))}
				</Row>
				
        <Row className="justify-content-center mt-10 mb-6">


                        <Link to="/our-team#clinical" className={`btn btn-primary gr-hover-y mr-6`}>
                            Meet the whole team
                        </Link>
                        <Link to="/for-specialists" className={`btn btn-outline-primary gr-hover-y`} style={{ color: "#2fbfcf" }}>
                            Join us
                        </Link>

                </Row>

				<hr />




                        <Row className="gr-text-8 mb-8 mt-12 px-4">
                            <h4>Frequently Asked Questions</h4>
                        </Row>
                        <Row>
                            <Col md="4">
                                <p style={{color: "#2fbfcf"}} className="gr-text-10 mb-6 px-4"><strong>Q: Do I need a referral to access psychiatry services at Akkadian Health?</strong></p>
                            </Col>
                            <Col md="8">
                                <p className="gr-text-10 mb-12 px-4"><strong>A: Yes, you can get a referral from your GP. Once we have your referral, you can book your ADHD assessment.</strong></p>
                            </Col>
                        </Row>                        
                        <Row >
                            <Col md="4">
                                <p style={{color: "#2fbfcf"}} className="gr-text-10 mb-6 px-4"><strong>Q: How do I book an appointment with Akkadian Health in {localState_maincity}, {localState_long} ?</strong></p>
                            </Col>
                            <Col md="8">
                                <p className="gr-text-10 mb-12 px-4"><strong>A: You can easily book your appointment online through our website or contact our team at <a href="tel:1800290880">1800 290 880</a>. We accept referrals from your GP.</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <p style={{color: "#2fbfcf"}} className="gr-text-10 mb-6 px-4"><strong>Q: Are your services covered by Medicare in {localState_maincity}, {localState_long} ?</strong></p>
                            </Col>
                            <Col md="8">
                                <p className="gr-text-10 mb-12 px-4"><strong>A: Yes, appointments with registered psychiatrists are eligible for Medicare rebates. For detailed information, please visit our <Link to="/faq">FAQ page</Link>.</strong></p>
                            </Col>
                        </Row>

                        <hr />


                        <Row className="justify-content-center text-center gr-text-10 gr-color-white mt-12 mb-6">
                            <Col md="8">
                                <h4>Get Started Today!</h4>
                                <p className="gr-text-10">To begin, get a referral from your GP! From there book an appointment with Akkadian Health and take the first step towards better mental health.  </p>
                                <p className="gr-text-10"><strong>We're here to support you every step of the way.</strong></p>
                            </Col>
                        </Row>


                <br />
            </Container>
        </div>
        <Divider />

				
				
			
      </PageWrapper>
    </>
		
		
    );
};

export default ACT;